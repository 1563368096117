import React,{useEffect} from 'react'
import img1 from "../../img/selab/selab2.jpeg"
const HomeContent3 = () => {
    useEffect(() => {
        function getRandomInt(max) {
            return Math.floor(Math.random() * max);
          }
          const counter1=getRandomInt(51030000);
          console.log("counter1",counter1)
          // const connt1=document.getElementById("count1").innerHTML=counter1
          // console.log("connt1",connt1)


          const counter2=getRandomInt(324234020);
          console.log("counter2",counter2)
          // const connt2=document.getElementById("count2").innerHTML=counter1
          // console.log("connt2",connt2)


          const counter3=getRandomInt(321123220);
          console.log("counter3",counter3)
          // const connt3=document.getElementById("count3").innerHTML=counter1
          // console.log("connt3",connt3)


          const counter4=getRandomInt(453434345);
          console.log("counter4",counter4)
          // const connt4=document.getElementById("count4").innerHTML=counter1
          // console.log("connt4",connt4)
     
    }, [])
    
  return (
   <div>
  {/* Service Start */}
  <div className="service">
    <div className="container">
      <div className="section-header text-center">
        <p>What We Do?</p>
        <h2>We believe that we can save more lifes with you</h2>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="service-item">
            <div className="service-icon">
              <i className="fa fa-hamburger" />
            </div>
            <div className="service-text">
              <h3>Healthy Food</h3>
              <p>Lorem ipsum dolor sit amet elit. Phase nec preti facils ornare velit non metus tortor</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service-item">
            <div className="service-icon">
            <i className="fa fa-tint" />
            </div>
            <div className="service-text">
              <h3>Pure Water</h3>
              <p>Lorem ipsum dolor sit amet elit. Phase nec preti facils ornare velit non metus tortor</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service-item">
            <div className="service-icon">
              <i className="fa fa-ambulance" />
            </div>
            <div className="service-text">
              <h3>Health Care</h3>
              <p>Lorem ipsum dolor sit amet elit. Phase nec preti facils ornare velit non metus tortor</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service-item">
            <div className="service-icon">
              <i className="fa fa-book-open" />
            </div>
            <div className="service-text">
              <h3>Primary Education</h3>
              <p>Lorem ipsum dolor sit amet elit. Phase nec preti facils ornare velit non metus tortor</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service-item">
            <div className="service-icon">
            <i class="fa fa-home" aria-hidden="true"></i>
            </div>
            <div className="service-text">
              <h3>Residence Facilities</h3>
              <p>Lorem ipsum dolor sit amet elit. Phase nec preti facils ornare velit non metus tortor</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service-item">
            <div className="service-icon">
            <i class="fas fa-hospital"></i>
            </div>
            <div className="service-text">
              <h3>Social Care</h3>
              <p>Lorem ipsum dolor sit amet elit. Phase nec preti facils ornare velit non metus tortor</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Service End */}
{/* Facts Start */}
{/* <div className="facts cover" data-parallax="scroll" data-image-src="img/facts.jpg"  data-jarallax="{&quot;speed&quot;: 0.6}" style={{ backgroundImage: `url(${img1})` }}>
  <div className="container">
    <div className="row">
      <div className="col-lg-3 col-md-6">
        <div className="facts-item">
          <i className="flaticon-home" />
          <div className="facts-text">
            <h3 className="facts-plus" data-toggle="counter-up" id='count1'></h3>
            <p>Countries</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="facts-item">
          <i className="flaticon-charity" />
          <div className="facts-text">
            <h3 className="facts-plus" id='count2' data-toggle="counter-up"></h3>
            <p>Volunteers</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="facts-item">
          <i className="flaticon-kindness" />
          <div className="facts-text">
            <h3 className="facts-dollar" data-toggle="counter-up" id='count3'></h3>
            <p>Our Goal</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="facts-item">
          <i className="flaticon-donation" />
          <div className="facts-text">
            <h3 className="facts-dollar" data-toggle="counter-up" id='count4'></h3>
            <p>Raised</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}
{/* Facts End */}

</div>

  )
}

export default HomeContent3