import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div>
        <div>
  {/* Footer Start */}
  <div className="footer fixed-bottom">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="footer-contact">
            <h2>Our Head Office</h2>
        
            <p><i className="fa fa-envelope" />admin@shwo.org</p>
            <div className="footer-social ">
              <a className="btn btn-custom" href><i className="fab fa-twitter" /></a>
             
              <a className="btn btn-custom" href><i className="fab fa-linkedin-in" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="footer-link">
            <h2>Popular Links</h2>
          
            <Link to="/"> Home</Link>
           <Link to="/About"> About</Link>
           <Link to="/Contact"> Contact</Link>
           <Link to="/Donate"> Donate</Link>
           <Link to="/Blog"> Blog</Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="footer-link">
            <h2>Useful Links</h2>
           <Link to="/"> Home</Link>
           <Link to="/About"> About</Link>
           <Link to="/Contact"> Contact</Link>
           <Link to="/Donate"> Donate</Link>
           <Link to="/Blog"> Blog</Link>

          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="footer-newsletter">
            <h2>Newsletter</h2>
            <form>
              <input className="form-control" placeholder="Email goes here" />
              <button className="btn btn-custom">Submit</button>
              <label>Don't worry, we don't spam!</label>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className="container copyright">
      <div className="row">
        <div className="col-md-6">
          <p className='text-bold'>© <Link to="/">Copyright 2022  All Right Reserved.</Link>: SHWO.</p>
        </div>
        <div className="col-md-6">
          <p>Designed By <Link to="/">Hnh Tech Solution</Link></p>
        </div>
      </div>
    </div>
  </div>

  <a href="#" className="back-to-top"><i className="fa fa-chevron-up" /></a>
</div>

    </div>
  )
}

export default Footer