import React,{useState} from 'react'
import img1 from '../../img/selab/selab4.jpeg'
import img2 from '../../img/selab/selab2.jpeg'
import img3 from '../../img/selab/selab3.jpeg'

import { Carousel ,Modal,Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
const HomeBanner = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <section className='justify-content-center'>
{/* Carousel Start */}

      <Carousel fade>
  
  <Carousel.Item  interval={1000}>
    <img
      className="d-block w-100"
      src={img1}
      alt="Second slide"
    />

    <Carousel.Caption>
    <div className="carousel-text text-center">
          <h1>Let's be kind for children</h1>
          <p>
            Lorem ipsum dolor sit amet elit. Phasellus ut mollis mauris. Vivamus egestas eleifend dui ac consequat at lectus in malesuada
          </p>
          <div className="carousel-btn">
          <Link className="btn btn-custom" to="/Donate">Donate Now</Link>
            <Link to="" className="btn btn-custom btn-play" data-toggle="modal" onClick={handleShow}>Watch Video</Link>
          </div>
        </div>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={1000}>
    <img
      className="d-block w-100"
      src={img2}
      alt="Third slide"
    />

    <Carousel.Caption>
    <div className="carousel-text text-center ">
          <h1>Get Involved with helping hand</h1>
          <p>
            Morbi sagittis turpis id suscipit feugiat. Suspendisse eu augue urna. Morbi sagittis, orci sodales varius fermentum, tortor
          </p>
          <div className="carousel-btn">
          <Link className="btn btn-custom" to="/Donate">Donate Now</Link>
            <a className="btn btn-custom btn-play" data-toggle="modal" data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-target="#videoModal">Watch Video</a>
          </div>
        </div>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={1000}>
    <img
      className="d-block w-100"
      src={img3}
      alt="Third slide"
    />

    <Carousel.Caption>
    <div className="carousel-text text-center">
          <h1>Bringing smiles to millions</h1>
          <p>
            Sed ultrices, est eget feugiat accumsan, dui nibh egestas tortor, ut rhoncus nibh ligula euismod quam. Proin pellentesque odio
          </p>
          <div className="carousel-btn">
            <Link className="btn btn-custom" to="/Donate">Donate Now</Link>
            <Link  to="" className="btn btn-custom btn-play"  >Watch Video</Link>
          </div>
        </div>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
{/* Carousel End */}
      </section>

<>

<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"md"}
      >
        <Modal.Header >
          <Modal.Title>Sealab video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <iframe width="450" height="315" src="https://www.youtube.com/embed/wZlLRBoFqL0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
</>
    </div>
  )
}

export default HomeBanner