import React from 'react'
import DonateBanner from '../components/Donate.js/DonateBanner'
import Header from '../components/Header'
import DonateContent from '../components/Donate.js/DonateContent'
import Footer from '../components/Footer'
const Doante = () => {
  return (
    <div>
      <Header/>
        <DonateBanner/>
        <DonateContent/>
        <Footer/>
        

    </div>
  )
}

export default Doante