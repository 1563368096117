import React from 'react'
import img1 from '../../img/selab/selab3.jpeg'
import img3 from '../../img/selab/selab2.jpeg'
import img2 from '../../img/selab/selab4.jpeg'
import img4 from '../../img/selab/selab6.jpeg'
import img6 from '../../img/selab/selab7.jpeg'
import img5 from '../../img/selab/selab3.jpeg'


const BlogContent = () => {
  return (
    <div>
{/* Blog Start */}
<div className="blog">
  <div className="container">
    <div className="section-header text-center">
      <p>Our Blog</p>
      <h2>Latest news &amp; articles directly from our blog</h2>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="blog-item">
          <div className="blog-img">
            <img src={img1} alt="Image" />
          </div>
          <div className="blog-text">
            <h3><a href="#">Lorem ipsum dolor sit</a></h3>
            <p>
              Lorem ipsum dolor sit amet elit. Neca pretim miura bitur facili ornare velit non vulpte liqum metus tortor
            </p>
          </div>
          <div className="blog-meta">
            <p><i className="fa fa-user" /><a href>Admin</a></p>
            <p><i className="fa fa-comments" /><a href>15 Comments</a></p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="blog-item">
          <div className="blog-img">
            <img src={img2} alt="Image" />
          </div>
          <div className="blog-text">
            <h3><a href="#">Lorem ipsum dolor sit</a></h3>
            <p>
              Lorem ipsum dolor sit amet elit. Neca pretim miura bitur facili ornare velit non vulpte liqum metus tortor
            </p>
          </div>
          <div className="blog-meta">
            <p><i className="fa fa-user" /><a href>Admin</a></p>
            <p><i className="fa fa-comments" /><a href>15 Comments</a></p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="blog-item">
          <div className="blog-img">
            <img src={img3} alt="Image" />
          </div>
          <div className="blog-text">
            <h3><a href="#">Lorem ipsum dolor sit</a></h3>
            <p>
              Lorem ipsum dolor sit amet elit. Neca pretim miura bitur facili ornare velit non vulpte liqum metus tortor
            </p>
          </div>
          <div className="blog-meta">
            <p><i className="fa fa-user" /><a href>Admin</a></p>
            <p><i className="fa fa-comments" /><a href>15 Comments</a></p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="blog-item">
          <div className="blog-img">
            <img src={img2} alt="Image" />
          </div>
          <div className="blog-text">
            <h3><a href="#">Lorem ipsum dolor sit</a></h3>
            <p>
              Lorem ipsum dolor sit amet elit. Neca pretim miura bitur facili ornare velit non vulpte liqum metus tortor
            </p>
          </div>
          <div className="blog-meta">
            <p><i className="fa fa-user" /><a href>Admin</a></p>
            <p><i className="fa fa-comments" /><a href>15 Comments</a></p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="blog-item">
          <div className="blog-img">
            <img src={img5} alt="Image" />
          </div>
          <div className="blog-text">
            <h3><a href="#">Lorem ipsum dolor sit</a></h3>
            <p>
              Lorem ipsum dolor sit amet elit. Neca pretim miura bitur facili ornare velit non vulpte liqum metus tortor
            </p>
          </div>
          <div className="blog-meta">
            <p><i className="fa fa-user" /><a href>Admin</a></p>
            <p><i className="fa fa-comments" /><a href>15 Comments</a></p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="blog-item">
          <div className="blog-img">
            <img src={img6} alt="Image" />
          </div>
          <div className="blog-text">
            <h3><a href="#">Lorem ipsum dolor sit</a></h3>
            <p>
              Lorem ipsum dolor sit amet elit. Neca pretim miura bitur facili ornare velit non vulpte liqum metus tortor
            </p>
          </div>
          <div className="blog-meta">
            <p><i className="fa fa-user" /><a href>Admin</a></p>
            <p><i className="fa fa-comments" /><a href>15 Comments</a></p>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <ul className="pagination justify-content-center">
          <li className="page-item disabled"><a className="page-link" href="#">Previous</a></li>
          <li className="page-item"><a className="page-link" href="#">1</a></li>
          <li className="page-item active"><a className="page-link" href="#">2</a></li>
          <li className="page-item"><a className="page-link" href="#">3</a></li>
          <li className="page-item"><a className="page-link" href="#">Next</a></li>
        </ul> 
      </div>
    </div>
  </div>
</div>
{/* Blog End */}

    </div>
  )
}

export default BlogContent