import React from 'react'
import AboutBanner from '../components/About./AboutBanner'
import Header from '../components/Header'
import AboutConent1 from '../components/About./AboutConent1'
import AboutContent2 from '../components/About./AboutContent2'
import Footer from '../components/Footer'
const About = () => {
  return (
    <div>
        <Header/>
        <AboutBanner/>
        <AboutConent1/>
        <AboutContent2/>
        <Footer/>


    </div>
  )
}

export default About