import axios from 'axios';
import React, { useState } from 'react'
import img1 from '../../img/selab/selab3.jpeg'
import swal from 'sweetalert2';
const ContactContent = () => {
  const axios = require('axios').default;

  const [Emailfrom, SetEmailfrom] = useState("")
  const [Emailto, SetEmailto] = useState("")
  const [Name, SetName] = useState("")
  const [Subject, SetSubject] = useState("")
  const [Description, SetDescription] = useState("")


  const ConatctApi = () => {
    // var axios = require('axios');
    // var FormData = require('form-data');
    var formData = new FormData();
    formData.append('to', "sheriyarali.sk@gmail.com");
    formData.append('email_from', Emailfrom);
    formData.append('name',Name);
    formData.append('subject', Subject);
    formData.append('description', Description);
    
    var config = {
      method: 'POST',
      url: 'http://shwoapi.shwo.org/api/mail',
      headers: { 
        'Accept': 'application/json',
      },
      data : formData
    };
    axios(config)
    .then(function (response) {
      console.log("response.data",response);

      if(response.status==200){
        swal.fire({
          title: "success",
          text: "Your Messege has been suceessful sent",
          icon: "success",
          confirmButtonColor: "#ff9900",
        })

      } else {
        swal.fire({
          title: "Opps",
          text: "Your Messege has been not sent",
          icon: "error",

        })

      }
    })
    .catch(function (error) {
      console.log(error);
      swal.fire({
        title: "Opps",
        text: "Your Messege has been not sent",
        icon: "error",

      })
    });
    
  }
  return (
    <div>

      {/* Contact Start */}
      <div className="contact">
        <div className="container">
          <div className="section-header text-center">
            <p>Get In Touch</p>
            <h2>Contact for any query</h2>
          </div>
          <div className="contact-img">
            <img src={img1} alt="Image" />
          </div>
          <div className="contact-form ">
            <div id="success" />
            <h2 className='text-light'>Contact US Form</h2>
            <form name="sentMessage" id="contactForm" noValidate="novalidate">
              <div className='row'>
                <div className='col-md-6'>
                  <div className="control-group">
                    <input disabled onChange={e=>SetEmailto(e.target.value)} type="emailto" className="form-control" id="email" placeholder="admin@shwo.org" required="required" data-validation-required-message="Please enter your email" />
                    <p className="help-block text-danger" />
                  </div>

                </div>
                <div className='col-md-6'>
                  <div className="control-group">
                    <input onChange={e=>SetEmailfrom(e.target.value)} type="emailfrom" className="form-control" id="email" placeholder="Your Email form" required="required" data-validation-required-message="Please enter your email" />
                    <p className="help-block text-danger" />
                  </div>

                </div>

              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className="control-group">
                    <input type="text" onChange={e=>SetName(e.target.value)} className="form-control" id="name" placeholder="Your Name" required="required" data-validation-required-message="Please enter your name" />
                    <p className="help-block text-danger" />
                  </div>

                </div>
                <div className='col-md-6'>
                  <div className="control-group">
                    <input type="text" onChange={e=>SetSubject(e.target.value)} className="form-control" id="subject" placeholder="Subject" required="required" data-validation-required-message="Please enter a subject" />
                    <p className="help-block text-danger" />
                  </div>

                </div>

              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <div className="control-group">
                    <textarea className="form-control"  onChange={e=>SetDescription(e.target.value)} id="message" placeholder="Message" required="required" data-validation-required-message="Please enter your message" defaultValue={""} />
                    <p className="help-block text-danger" />
                  </div>
                </div>

              </div>


              <div>
                <button onClick={()=>ConatctApi()} className="btn btn-custom" type="button" id="sendMessageButton">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Contact End */}

    </div>
  )
}

export default ContactContent