import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import BlogBanner from '../components/Blog/BlogBanner'
import BlogContent from '../components/Blog/BlogContent'

const Blogs = () => {
  return (
    <div>
        <Header/>
        <BlogBanner/>
        <BlogContent/>
        <Footer/>
    </div>
  )
}

export default Blogs;