import React from 'react'
import img1 from '../../img/selab/selab3.jpeg'
const AboutBanner = () => {
  return (
    <div>
      {/* Page Header Start */}
<div className="page-header cover" style={{ backgroundImage: `url(${img1})` }}>
  <div className="container" >
    <div className="row">
      <div className="col-12">
        <h2>About Us</h2>
      </div>
      <div className="col-12">
        <a href>Home</a>
        <a href>About Us</a>
      </div>
    </div>
  </div>
</div>
{/* Page Header End */}

    </div>
  )
}

export default AboutBanner