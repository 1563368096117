import React from 'react'
import ContactBanner from '../components/Contact/ContactBanner'
import ContactContent from '../components/Contact/ContactContent'
import Header from '../components/Header'
import Footer from '../components/Footer'
const Contact = () => {
  return (
    <div>
        <Header/>
        <ContactBanner/>
        <ContactContent/>
        <Footer/>
    </div>
  )
}

export default Contact