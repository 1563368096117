import React from 'react'
import img1 from '../../img/Donate.jpg'

const DonateBanner = () => {
  return (
    <div>
        {/* Page Header Start */}
<div className="page-header cover" style={{ backgroundImage: `url(${img1})` }}>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <h2>Donate Now</h2>
      </div>
      <div className="col-12">
        <a href>Home</a>
        <a href>Donate</a>
      </div>
    </div>
  </div>
</div>
{/* Page Header End */}

    </div>
  )
}

export default DonateBanner