import React, { useRef, useState } from 'react'
import img1 from '../../img/stripe.png'
import img2 from '../../img/Paypal.png'
import Swal from 'sweetalert2';
import StripeCheckout from 'react-stripe-checkout';
import ReactPhoneInput from "react-phone-input-2";
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import {
    Typography,
    AppBar,
    Toolbar,
    TextField,
    Button,
    Box,


} from "@material-ui/core";

import 'react-phone-input-2/lib/style.css'
const DonateContent = () => {
    const [Premiumnameamount, setPremiumnameamount] = useState(449 * 100);
    const [number, Setnumber] = useState("")
    const [amount, Setamount] = useState("")
    const [firstname, Setfirstname] = useState("")
    const [lastname, Setlastname] = useState("")
    const [Email, SetEmail] = useState("")
    const [Description, SetDescription] = useState("")
    const [currency, setcurrency] = useState("")
    const [hidden, setHidden] = useState(true);


    const [Select, SetSelect] = useState("")




    const premiumpaymentRef = useRef(null)
    const Premium = () => {
        premiumpaymentRef.current.onClick()
        return
    }
    const handleOnChange = (...args) => {
        Setnumber(args[3]);
        console.log("number", number)
        console.log("number", number[3])

    };
    const Stripe = (token) => {
        console.log("token", token.id)
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`http://shwoapi.shwo.org/api/stripe_payment_process?stripeToken=${token.id}&name=${firstname}&email=${Email}&currency=${currency}&amount=${amount}&number=${number}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)


            })
            .catch(error => console.log('error', error));
    }


    console.log(currency)

    // paypal

    const paypal = () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        var formdata = new FormData();
        formdata.append("name", firstname);
        formdata.append("email", Email);
        formdata.append("currency", "usd");
        formdata.append("amount", amount);
        formdata.append("number", number);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://shwoapi.shwo.org/api/payment", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status === true) {
                    // console.log("result paypal", result.data)
                    window.location.href = result.message.paypal_link
                } else if (result.status === false) {
                    return window.location.href = "https://valet-userside.vercel.app/login"
                } else {
                    // console.log("elsee", result.message)
                    Swal.fire({
                        title: "Oops",
                        text: result.message,
                        icon: "error",
                        confirmButtonColor: "#03bafe",
                    });
                }

            })
            .catch(error => console.log('error', error));
    }

    const [PaymentMethodType, setPaymentMethodType] = useState("")


    return (
        <div>
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8'>
                            <div className="card text-dark mt-3 mb-3 bg-warning shadow" style={{ width: '100%' }}>

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control onChange={e => Setfirstname(e.target.value)} type="name" placeholder="name" />
                                            </Form.Group>

                                        </div>
                                        <div className='col-md-6'>
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control onChange={e => SetEmail(e.target.value)} type="email" placeholder="name@example.com" />
                                            </Form.Group>

                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>



                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                <Form.Label> Select Currency</Form.Label>
                                                <Form.Control onChange={e => setcurrency(e.target.value)} as="select">
                                                    <option value="usd">USD</option>
                                                    <option value="aud">AUD</option>
                                                    <option value="ars">ARS</option>
                                                    <option value="bng">BGN</option>
                                                    <option value="eur">EUR</option>
                                                </Form.Control>
                                            </Form.Group>


                                        </div>
                                        <div className='col-md-6'>
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Label>Amount</Form.Label>
                                                <Form.Control onChange={e => Setamount(e.target.value)} type="number" placeholder="1000" />
                                            </Form.Group>


                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>Number</label>
                                            <ReactPhoneInput
                                                style={{ backgroundColor: "transparent" }} onChange={handleOnChange} defaultCountry="nl" enableSearchField />




                                        </div>
                                        <div className='col-md-6 text-inilne'>
                                            <Form.Group className="mt-1 " controlId="formBasicCheckbox ">
                                                <Form.Check
                                                    type="radio"
                                                    label="Payment via CreditCard/DebitCard"
                                                    onClick={() => setPaymentMethodType("stripe")}
                                                    name="formHorizontalRadios"
                                                    id="formHorizontalRadios1"

                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Payment via paypal"
                                                    name="formHorizontalRadios"
                                                    id="formHorizontalRadios2"
                                                    onClick={() => setPaymentMethodType("paypal")}

                                                />


                                            </Form.Group>

                                        </div>


                                    </div>
                                    <div className='row justify-content-center mt-3'>
                                        <div className='col-md-6 text-center'>
                                            <button className='btb btn-pills btn-block btn-dark px-4 ' onClick={() => { PaymentMethodType === "stripe" ? Premium() : paypal() }}> Submit</button>
                                        </div>


                                    </div>
                                    <div>



                                    </div>

                                </div>
                            </div>


                        </div>


                    </div>
                    {/* <div className='row justify-content-center'>
                        <div className='col-md-4' >
                            <div className="card bg-light shadow py-3" style={{ width: '100%' }}>
                                <div className="card-body">
                                    <img src={img1} onClick={Premium} width="100%" className="card-img-top" alt="..." />

                                </div>
                            </div>


                        </div>
                        <div className='col-md-4 '>
                            <div className="card bg-light shadow" style={{ width: '100%' }}>
                                <div className="card-body">
                                    <img src={img2} width="100%" className="card-img-top" alt="..." />

                                </div>
                            </div>

                        </div>

                    </div> */}
                    <div className='row'>
                        <div className='col-md-12'>
                            {!hidden ? <p>You can see me!</p> : null}

                        </div>
                    </div>

                </div>

            </section>
            <StripeCheckout
                name="Show"
                image={img1}
                ref={premiumpaymentRef}
                // currency={currency}
                className="d-none"
                amount={amount * 100}
                token={Stripe}
                stripeKey="pk_test_51LOeZrEMUxe93PzTRJA7B7JAKMqy1TRTHOoXj8ZzyUnDeSMxRVG1j8BhuHxAM3JdRWyyhyjYV65B7KBUMNpnpP2n00dkzdaBhq"
            />
        </div>
    )
}

export default DonateContent