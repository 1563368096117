import React from 'react'
import img1 from "../../img/team-1.jpg"
import img2 from "../../img/team-2.jpg"
import img3 from "../../img/team-3.jpg"
import img4 from "../../img/team-4.jpg"
import  OwlCarousel  from 'react-owl-carousel'
import img5 from '../../img/testimonial-1.jpg'
import img6 from '../../img/testimonial-2.jpg'
import img7 from '../../img/testimonial-3.jpg'
import img8 from '../../img/testimonial-4.jpg'

const AboutContent2 = () => {
  return (
    <div>
{/* Team Start */}
{/* <div className="team">
  <div className="container">
    <div className="section-header text-center">
      <p>Meet Our Team</p>
      <h2>Awesome guys behind our charity activities</h2>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-6">
        <div className="team-item">
          <div className="team-img">
            <img src={img1} width={"100%"} alt="Team Image" />
          </div>
          <div className="team-text">
            <h2>Donald John</h2>
            <p>Founder &amp; CEO</p>
            <div className="team-social">
              <a href><i className="fab fa-twitter" /></a>
              <a href><i className="fab fa-facebook-f" /></a>
              <a href><i className="fab fa-linkedin-in" /></a>
              <a href><i className="fab fa-instagram" /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="team-item">
          <div className="team-img">
            <img src={img2} width={"100%"} alt="Team Image" />
          </div>
          <div className="team-text">
            <h2>Adam Phillips</h2>
            <p>Chef Executive</p>
            <div className="team-social">
              <a href><i className="fab fa-twitter" /></a>
              <a href><i className="fab fa-facebook-f" /></a>
              <a href><i className="fab fa-linkedin-in" /></a>
              <a href><i className="fab fa-instagram" /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="team-item">
          <div className="team-img">
            <img src={img3} width={"100%"} alt="Team Image" />
          </div>
          <div className="team-text">
            <h2>Thomas Olsen</h2>
            <p>Chef Advisor</p>
            <div className="team-social">
              <a href><i className="fab fa-twitter" /></a>
              <a href><i className="fab fa-facebook-f" /></a>
              <a href><i className="fab fa-linkedin-in" /></a>
              <a href><i className="fab fa-instagram" /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="team-item">
          <div className="team-img">
            <img src={img4} width={"100%"} alt="Team Image" />
          </div>
          <div className="team-text">
            <h2>James Alien</h2>
            <p>Advisor</p>
            <div className="team-social">
              <a href><i className="fab fa-twitter" /></a>
              <a href><i className="fab fa-facebook-f" /></a>
              <a href><i className="fab fa-linkedin-in" /></a>
              <a href><i className="fab fa-instagram" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}
{/* Team End */}
{/* <div className="testimonial">
  <div className="container">
    <div className="section-header text-center">
      <p>Testimonial</p>
      <h2>What people are talking about our charity activities</h2>
    </div>

    <OwlCarousel className='owl-theme' loop margin={4} nav>
    <div className="testimonial-item">
        <div className="testimonial-profile">
          <img src={img5} alt="Image" />
          <div className="testimonial-name">
            <h3>Person Name</h3>
            <p>Profession</p>
          </div>
        </div>
        <div className="testimonial-text">
          <p>
            Lorem ipsum dolor sit amet elit. Phasel preti mi facilis ornare velit non vulputa. Aliqu metus tortor, auctor id gravid vivera quis
          </p>
        </div>
      </div>
      <div className="testimonial-item">
        <div className="testimonial-profile">
          <img src={img6} width="100%" alt="Image" />
          <div className="testimonial-name">
            <h3>Person Name</h3>
            <p>Profession</p>
          </div>
        </div>
        <div className="testimonial-text">
          <p>
            Lorem ipsum dolor sit amet elit. Phasel preti mi facilis ornare velit non vulputa. Aliqu metus tortor, auctor id gravid vivera quis
          </p>
        </div>
      </div>
      <div className="testimonial-item">
        <div className="testimonial-profile">
          <img src={img7} width="100%" alt="Image" />
          <div className="testimonial-name">
            <h3>Person Name</h3>
            <p>Profession</p>
          </div>
        </div>
        <div className="testimonial-text">
          <p>
            Lorem ipsum dolor sit amet elit. Phasel preti mi facilis ornare velit non vulputa. Aliqu metus tortor, auctor id gravid vivera quis
          </p>
        </div>
      </div>
      <div className="testimonial-item">
        <div className="testimonial-profile">
          <img src={img8} alt="Image" />
          <div className="testimonial-name">
            <h3>Person Name</h3>
            <p>Profession</p>
          </div>
        </div>
        <div className="testimonial-text">
          <p>
            Lorem ipsum dolor sit amet elit. Phasel preti mi facilis ornare velit non vulputa. Aliqu metus tortor, auctor id gravid vivera quis
          </p>
        </div>
      </div>
 
</OwlCarousel>;


  </div>
</div> */}




    </div>
  )
}

export default AboutContent2
